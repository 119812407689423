import React from "react";

import CorvallisLogo from "../../images/Corvallis Logo.png";
import Filter from "../../images/Filter.png";
import Map from "../../images/Map.png";
import Saved from "../../images/Saved.png";
import Details from "../../images/Details.png";

import PortfolioPage from "../../components/portfolioPage";
import Highlight from "../../components/highlight";

const links = [
	{
		endpoint:
			"https://github.com/FellowshipOfThePing/Corvallis-Off-Campus-Mobile",
		title: "Github",
	},
];

const highlights = [
	{
		image: Details,
		text:
			"View and compare listings quickly - Users only have to click once to see the information they actually want",
	},
	{
		image: Map,
		text: "Browse listings by list or by location",
	},
	{
		image: Filter,
		text:
			"Filter apartments by metrics that matter to students, like distance/drive/walk time to campus",
	},
	{
		image: Saved,
		text: "Save listings to compare later",
	},
];

const Corvallis = () => (
	<PortfolioPage
		image={CorvallisLogo}
		name="Corvallis Off Campus"
		description="Housing aggregator for Oregon State University students to find apartments."
		links={links}
	>
		{highlights.map((item, index) => (
			<Highlight
				text={item.text}
				image={item.image}
				style={
					index === 0
						? {
								borderTop: "1px solid black",
								paddingTop: "50px",
								marginBottom: "50px",
						  }
						: { marginBottom: "50px" }
				}
				heightRatio={0.9}
				textLeft={index % 2 === 0}
			/>
		))}
	</PortfolioPage>
);

export default Corvallis;
